import React from "react";

import { Provider } from "react-redux";
import { profileStore } from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import Profile from "../coms/user/Profile";

const ProfilePage = () => {
  return (
    <>
      <Seo title="My Profile on Schoode" pathname="/profile" />
      <Provider store={profileStore}>
        <MainFrame>
          <Layout>
            <Profile />
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default ProfilePage;
