import React, { useRef, useState } from "react";

import { makeStyles } from "@mui/styles";

import { EditBtn } from "../../tools/IconBtn";
import ProfileAvatar from "./ProfileAvatar";
import ProfileName from "./ProfileName";

import { DIR_CDN_IMG } from "../../data/adresses";
import UserAPI from "../../api/userApi";

import { useDispatch } from "react-redux";
import { updateProfileData } from "../../data/profile";
import { setStatusMsg } from "../../data/settings";

const ProfileHead = ({ user, pText, pOwner, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  const fileRef = useRef(null);

  const hChangeHeadImg = (event) => {
    if (event) {
      const img = event.target.files[0];
      if (img) {
        setBusy(true);
        const pload = {
          kind: "bk",
          old: pData.bk_img ? pData.bk_img : "x",
          imgFile: img,
        };

        UserAPI.setImg(
          user,
          pload,
          (m) => {
            setBusy(false);
            dispatch(updateProfileData({ bk_img: m }));
          },
          (x) => {
            setBusy(false);
            dispatch(setStatusMsg([3, "gen"]));
          }
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        onChange={hChangeHeadImg}
        style={{ display: "none" }}
      />

      <img
        crossOrigin="anonymous"
        alt=""
        className={classes.bkImage}
        src={DIR_CDN_IMG + (pData.bk_img ? pData.bk_img : "user-bk1.jpg")}
      />

      <div className={classes.control}>
        {pOwner && (
          <EditBtn
            busy={busy}
            onClick={() => {
              if (fileRef.current) fileRef.current.click();
            }}
          />
        )}
      </div>

      <div className={classes.avaBox}>
        <ProfileAvatar user={user} pOwner={pOwner} pData={pData} />

        <ProfileName user={user} pText={pText} pOwner={pOwner} pData={pData} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "300px",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  bkImage: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    objectFit: "cover",
    width: "100%",
    height: "230px",
  },
  control: {
    display: "block",
    position: "absolute",
    top: 20,
    right: 20,
  },
  avaBox: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%",
    bottom: 0,
    left: 0,
    padding: theme.spacing(1),
  },
}));

export default ProfileHead;
