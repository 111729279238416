import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { Stack, Typography } from "@mui/material";

import { EditBtn } from "../../tools/IconBtn";
import TextDialog from "../../tools/TextDialog";

import UserAPI from "../../api/userApi";
import { useDispatch } from "react-redux";
import { setStatusMsg } from "../../data/settings";
import { updateProfileData } from "../../data/profile";

const Description = ({ user, pText, pOwner, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const hSave = (txt) => {
    if (busy) return;
    if (txt === pData.description) return;

    setBusy(true);
    UserAPI.updateInfo(
      user,
      { description: txt },
      (m) => {
        setBusy(false);
        dispatch(updateProfileData({ description: txt }));
      },
      (x) => {
        setBusy(false);
        if (x.e !== undefined) {
          dispatch(setStatusMsg([2, pText.desErr]));
        } else {
          dispatch(setStatusMsg([3, "server"]));
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      <Stack spacing={2}>
        <div className={classes.edBtn}>
          <Typography margin="0px 10px" gutterBottom variant="subtitle1">
            {pText.desc}
          </Typography>
          {pOwner && (
            <EditBtn
              busy={busy}
              onClick={() => {
                setOpen(true);
              }}
            />
          )}
        </div>
        <div className={classes.desc}>
          <Typography
            className={classes.deTxt}
            color="textSecondary"
            variant="body2"
          >
            {pData.description ? pData.description : ""}
          </Typography>
        </div>
      </Stack>

      <TextDialog
        open={open}
        close={() => {
          setOpen(false);
        }}
        txt={pData.description}
        setTxt={(x) => {
          hSave(x);
          setOpen(false);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    minHeight: "200px",
    padding: theme.spacing(2, 1),
    overflow: "hidden",
  },
  edBtn: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  desc: {
    position: "relative",
    width: "100%",
    minHeight: "100px",
    padding: theme.spacing(1, 2),
    overflow: "hidden",
  },
  deTxt: {
    width: "100%",
    whiteSpace: "break-word",
    maxLines: 10,
    TextOverflow: "ellipsis",
  },
}));

export default Description;
