import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/material";

import LoadMore from "../tools/LoadMore";
import PjtCard from "./projects/PjtCard";

import ProjectAPI from "../api/projectApi";
import { useDispatch } from "react-redux";
import { setStatusMsg } from "../data/settings";
import CreateProjectBtn from "../project/CreateProjectBtn";

const MyProjects = ({ user, pOwner, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [feedsList, setFeedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const calcDateMsg = (ts) => {
    try {
      var date = new Date(ts);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } catch (error) {}
    return "";
  };

  const hDelete = async (id) => {
    if (deleting) return;
    if (user) {
      setDeleting(true);
      ProjectAPI.deleteProject(
        user,
        id,
        (m) => {
          setDeleting(false);
          if (m > 0) {
            const ss = [];
            feedsList.forEach((itm) => {
              if (itm.id !== id) ss.push(itm);
            });
            setFeedList(ss);
          }
        },
        (x) => {
          setDeleting(false);
          dispatch(setStatusMsg([3, "gen"]));
        }
      );
    }
  };

  const loadFeeds = async () => {
    if (loading) return;

    if (pData) {
      const id = pData.id;
      if (id) {
        setLoading(true);
        ProjectAPI.getUserFeeds(
          user,
          id,
          feedsList.length,
          10,
          (m) => {
            setLoading(false);
            if (Array.isArray(m)) {
              if (m.length > 0) {
                const ss = [...feedsList];
                m.forEach((itm) => {
                  ss.push({ ...itm, time: calcDateMsg(itm.created_at) });
                });
                setFeedList(ss);
              }
            }
          },
          (x) => {
            setLoading(false);
            dispatch(setStatusMsg([3, "gen"]));
          }
        );
      }
    }
  };

  const hLoadMore = () => {
    loadFeeds();
  };

  useEffect(() => {
    loadFeeds();
  }, []);

  const fillFeeds = () => {
    if (Array.isArray(feedsList)) {
      if (feedsList.length > 0) {
        return (
          <>
            <div className={classes.content}>
              {feedsList.map((itm, i) => (
                <PjtCard
                  key={i}
                  itmData={itm}
                  pOwner={pOwner}
                  hDelete={hDelete}
                  deleting={deleting}
                />
              ))}
            </div>

            <LoadMore loading={loading} onClick={hLoadMore} />
          </>
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <Stack spacing={2}>
        {pOwner && <CreateProjectBtn />}
        {fillFeeds()}
      </Stack>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    backgroundColor: "#fafafa",
    width: "100%",
    minHeight: "250px",
    padding: "8px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    width: "100%",
  },
}));

export default MyProjects;
