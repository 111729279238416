import React, { useEffect, useState } from "react";

import { Container, Paper, Stack, Tabs, Tab } from "@mui/material";

import LoadingPaper from "../tools/loadingPaper";

import ProfileHead from "./head/ProfileHead";
import MyProjects from "./MyProjects";
import AboutMe from "./AboutMe";

import { getUrlParams, goHomePage } from "../tools/browserHelpers";

import UserAPI from "../api/userApi";

import { useSelector, useDispatch } from "react-redux";
import { setProfileOwner, setProfileData } from "../data/profile";

const Profile = () => {
  const dispach = useDispatch();
  const user = useSelector((s) => s.settings.user);
  const pText = useSelector((s) => s.settings.texts.profile);
  const pData = useSelector((s) => s.profile.data);
  const pOwner = useSelector((s) => s.profile.owner);

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const hChangeTab = (ev, i) => {
    setTab(i);
  };

  const loadCloudProfile = (xid, xowner) => {
    if (loading) return;

    setLoading(true);
    UserAPI.getInfo(
      user,
      xid,
      (m) => {
        setLoading(false);
        if (m.id !== undefined) {
          dispach(setProfileData(m));
          dispach(setProfileOwner(xowner));
        } else {
          goHomePage();
        }
      },
      (x) => {
        setLoading(false);
        goHomePage();
      }
    );
  };

  useEffect(() => {
    var xowner = false;
    var xid = null;

    const urlParam = getUrlParams();
    if (urlParam) {
      if (urlParam.id !== undefined) {
        xid = urlParam.id;
      }
    }

    if (user) {
      if (!xid) {
        xid = user.id;
      }
      if (user.id === xid) {
        xowner = true;
      }
    }

    if (xid) {
      loadCloudProfile(xid, xowner);
    } else {
      goHomePage();
    }
  }, []);

  const showContent = () => {
    if (tab === 1) {
      return (
        <AboutMe user={user} pOwner={pOwner} pData={pData} pText={pText} />
      );
    }

    if (tab === 0) {
      return <MyProjects user={user} pOwner={pOwner} pData={pData} />;
    }

    return <></>;
  };

  const showProfile = () => {
    if (pData && pText) {
      return (
        <Stack width="100%" spacing={2}>
          <ProfileHead
            user={user}
            pText={pText}
            pOwner={pOwner}
            pData={pData}
          />

          <Paper square style={{ width: "100%" }}>
            <Tabs
              value={tab}
              aria-label=""
              textColor="inherit"
              indicatorColor="primary"
              onChange={hChangeTab}
            >
              <Tab label={pText.projects} />
              <Tab label={pText.about} />
            </Tabs>
          </Paper>

          {showContent()}
        </Stack>
      );
    }

    return <></>;
  };

  return (
    <Container maxWidth="lg">
      {showProfile()}
      <LoadingPaper open={loading} />
    </Container>
  );
};

export default Profile;
