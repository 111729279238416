import React from "react";

import { Divider } from "@mui/material";

import Description from "./about/Description";
import SecuritySection from "./about/SecuritySection";

const AboutMe = ({ user, pOwner, pData, pText }) => {
  const showSecurity = () => {
    if (pOwner) {
      return (
        <>
          <Divider variant="middle" />
          <SecuritySection user={user} pText={pText} pData={pData} />
        </>
      );
    }

    return <></>;
  };

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        width: "100%",
        backgroundColor: "#fff",
        overflow: "hidden",
        padding: "16px 8px",
      }}
    >
      <Description user={user} pText={pText} pOwner={pOwner} pData={pData} />
      {showSecurity()}
    </div>
  );
};

export default AboutMe;
