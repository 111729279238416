import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";

import { CancelBTN, OkBTN } from "../../tools/buttons";
import { EditBtn } from "../../tools/IconBtn";
import CModal from "../../tools/CModal";

import UserAPI from "../../api/userApi";
import { useDispatch } from "react-redux";
import { updateProfileData } from "../../data/profile";
import { setStatusMsg, updateUser } from "../../data/settings";

const ProfileName = ({ user, pText, pOwner, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const hChangeUserName = () => {
    if (busy) return;

    setBusy(true);
    const data = { fname: fName, lname: lName };

    UserAPI.updateInfo(
      user,
      data,
      (m) => {
        setBusy(false);
        dispatch(updateProfileData({ fname: fName, lname: lName }));
        if (pOwner) {
          dispatch(updateUser({ fname: fName, lname: lName }));
        }
      },
      (x) => {
        setBusy(false);
        dispatch(setStatusMsg([3, "gen"]));
      }
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>{pData.fname + " " + pData.lname}</div>

      <div className={classes.control}>
        {pOwner && (
          <EditBtn
            busy={busy}
            onClick={() => {
              setFName(pData.fname);
              setLName(pData.lname);
              setOpen(true);
            }}
          />
        )}
      </div>

      <CModal
        open={open}
        close={() => {
          setOpen(false);
        }}
      >
        <Grid container spacing={2} padding="15px 5px">
          <Grid item xs={12} sm={6}>
            <TextField
              title=""
              fullWidth
              autoFocus
              variant="outlined"
              label={pText.name1}
              value={fName}
              onChange={(event) => {
                setFName(event.target.value.trim());
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              title=""
              fullWidth
              autoFocus
              variant="outlined"
              label={pText.name2}
              value={lName}
              onChange={(event) => {
                setLName(event.target.value.trim());
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <CancelBTN
              onClick={() => {
                setOpen(false);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <OkBTN
              onClick={() => {
                setOpen(false);
                hChangeUserName();
              }}
            />
          </Grid>
        </Grid>
      </CModal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    padding: theme.spacing(0, 1),
  },
  control: {
    position: "absolute",
    display: "block",
    top: -16,
    left: 0,
  },
  title: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: theme.spacing(0, 2),
    fontWeight: "500",
    fontSize: "16px",

    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
}));

export default ProfileName;
