import React, { useState, useRef } from "react";

import { makeStyles } from "@mui/styles";

import { DIR_CDN_IMG } from "../../data/adresses";

import { EditBtn } from "../../tools/IconBtn";
import UserAPI from "../../api/userApi";
import { useDispatch } from "react-redux";
import { updateProfileData } from "../../data/profile";
import { setStatusMsg } from "../../data/settings";

const ProfileAvatar = ({ user, pOwner, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fileRef = useRef(null);

  const [busy, setBusy] = useState(false);

  var imgSrc = DIR_CDN_IMG + "user-m1.png";
  if (pData.ava_img) {
    imgSrc = DIR_CDN_IMG + pData.ava_img;
  } else if (pData.gender !== undefined) {
    if (pData.gender === "f") {
      imgSrc = DIR_CDN_IMG + "user-f1.png";
    }
  }

  const hChangeImgFile = (event) => {
    if (busy) return;

    if (event) {
      const img = event.target.files[0];
      if (img) {
        setBusy(true);
        const pload = {
          old: pData.ava_img ? pData.ava_img : "x",
          kind: "ava",
          imgFile: img,
        };

        UserAPI.setImg(
          user,
          pload,
          (m) => {
            setBusy(false);
            dispatch(updateProfileData({ ava_img: m }));
          },
          (x) => {
            setBusy(false);
            dispatch(setStatusMsg([3, "gen"]));
          }
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <input
        type="file"
        ref={fileRef}
        name="avatar"
        accept="image/*"
        onChange={hChangeImgFile}
        style={{ display: "none" }}
      />

      <div className={classes.imageBox}>
        <img
          crossOrigin="anonymous"
          alt=""
          style={{
            position: "relative",
            display: "block",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={imgSrc}
        />
      </div>

      <div className={classes.control}>
        {pOwner && (
          <EditBtn
            busy={busy}
            onClick={() => {
              if (fileRef.current) fileRef.current.click();
            }}
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    padding: theme.spacing(0, 1),
  },
  control: {
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
  },
  imageBox: {
    width: "80px",
    height: "80px",
    overflow: "hidden",
    borderRadius: "50%",
    backgroundColor: "#fff",
    padding: "1px",

    [theme.breakpoints.up("sm")]: {
      width: "120px",
      height: "120px",
    },
  },
}));

export default ProfileAvatar;
