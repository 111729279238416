import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import {
  Modal,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

import { CancelBTN, OkBTN } from "../../tools/buttons";
import { EditBtn } from "../../tools/IconBtn";

import UserAPI from "../../api/userApi";
import { useDispatch } from "react-redux";
import { setStatusMsg } from "../../data/settings";

const SecuritySection = ({ user, pText, pData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);
  const [verfy, setVerfy] = useState(0);
  const [open, setOpen] = useState(false);
  const [opwd, setOpwd] = useState("");
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [errMsg, setErr] = useState("");

  const hChangePwd = () => {
    if (busy) return;

    if (opwd.length < 1 || pwd1.length < 1 || pwd2.length < 1) {
      setErr(pText.reqAll);
      return;
    }

    if (pwd1 !== pwd2) {
      setErr(pText.reqEqu);
      return;
    }

    setOpen(false);
    setBusy(true);
    UserAPI.pwdSet(
      user,
      { old: opwd, pwd: pwd1 },
      (m) => {
        setBusy(false);
        dispatch(setStatusMsg([1, pText.pwdOk]));
      },
      (x) => {
        setBusy(false);
        dispatch(setStatusMsg([2, pText.pwdErr]));
      }
    );
  };

  const hReqVerify = () => {
    if (verfy > 0) return;

    setVerfy(1);
    UserAPI.reqVerify(
      user,
      (m) => {
        setVerfy(2);
        dispatch(setStatusMsg([1, pText.emVerOk]));
      },
      (x) => {
        setVerfy(0);
        if (x.e !== undefined) {
          dispatch(setStatusMsg([3, "gen"]));
          return;
        }
        dispatch(setStatusMsg([3, "server"]));
      }
    );
  };

  const showVerify = () => {
    if (pData.active > 0) {
      return <CheckCircleOutlineIcon color="success" />;
    }

    if (verfy === 1) {
      return <CircularProgress color="secondary" size={25} />;
    }

    return (
      <Button
        color="warning"
        onClick={() => {
          hReqVerify();
        }}
      >
        <ReportGmailerrorredOutlinedIcon />
        {verfy > 0 ? pText.sent : pText.verify}
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.flxRaw}>
        <Typography margin="0px 10px" gutterBottom variant="subtitle1">
          {pText.email} :
        </Typography>
        {showVerify()}
      </div>
      <div className={classes.flxRaw} style={{ paddingBottom: "20px" }}>
        <Typography margin="0px 10px" gutterBottom variant="subtitle1">
          {pData.email}
        </Typography>
      </div>
      <div className={classes.flxRaw} style={{ paddingBottom: "20px" }}>
        <Typography margin="0px 10px" gutterBottom variant="subtitle1">
          {pText.pwd} : ****
        </Typography>
        <EditBtn
          busy={busy}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>

      <Modal
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby=""
        aria-describedby=""
      >
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                title=""
                variant="outlined"
                margin="normal"
                type="password"
                required
                fullWidth
                value={opwd}
                onChange={(event) => {
                  setOpwd(event.target.value);
                }}
                label={pText.oldPwd}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                title=""
                variant="outlined"
                margin="normal"
                type="password"
                required
                fullWidth
                value={pwd1}
                onChange={(event) => {
                  setPwd1(event.target.value);
                }}
                label={pText.newPwd}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                title=""
                variant="outlined"
                margin="normal"
                type="password"
                required
                fullWidth
                value={pwd2}
                onChange={(event) => {
                  setPwd2(event.target.value);
                }}
                label={pText.newPwd2}
              />
            </Grid>
            <Grid item xs={12}>
              {errMsg.length > 0 && <Alert severity="error">{errMsg}</Alert>}
            </Grid>

            <Grid item xs={6} textAlign="center" justifyContent="center">
              <CancelBTN
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>

            <Grid item xs={6} textAlign="center" justifyContent="center">
              <OkBTN
                onClick={() => {
                  hChangePwd();
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: theme.spacing(2, 1),
    overflow: "hidden",
  },
  flxRaw: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(5, 3),
    maxWidth: "350px",
  },
}));

export default SecuritySection;
